/*_   _   _
 / \ / \ / \
( B | E | M )
 \_/ \_/ \_/
 - Props................block properties only.
 - Elems................elements' Block.
 - Childrens............kids of Elements (if present).
 - Modifiers............modify the aspect of Element.
 - States...............hover, focus, active or class for temporary state.
 - Anims................keyframes.
 - Patches..............quick fixes.
*/
.sidebar {
    /*------------------------------------*\
      #PROPS
    \*------------------------------------*/
    font-size: 13px;
    /*------------------------------------*\
      #ELEMS
    \*------------------------------------*/
    &__section {
        margin-bottom: 10px;
    }
    &__legend {
        font-weight: bold;
        margin-right: 10px;
    }
    &__value {
        color: $primary-color;
        display: inline-block;
        position: relative;
        font-weight: bold;
    }
    &__cta {
        text-decoration: underline;
        cursor: pointer;
        margin-left: 10px;
    }
    .button, select {
      margin: 2.5px;
    }
    &__message {
      padding: 15px 10px;
      margin: 10px 0;
      border: 1px solid #ccc;
      strong, a {
        white-space: pre;
      }
    }
    /*------------------------------------*\
      #CHILDRENS
    \*------------------------------------*/

    /*------------------------------------*\
      #MODIFIERS
    \*------------------------------------*/
    &__section--form {
      margin: 10px 0 20px;
    }
    &__message--highlight {
      background-color: lighten($primary-color, 10%);
      font-size: 15px;
      animation: message 1s forwards;
      border: 0;
      border-radius: 5px;
    }
    /*------------------------------------*\
      #STATES
    \*------------------------------------*/

    /*------------------------------------*\
      #ANIMS
    \*------------------------------------*/
    @keyframes message {
      from {
      }
      to {
        background-color: lighten($primary-color, 50%);
      }
    }
    /*------------------------------------*\
      #PATCHES
    \*------------------------------------*/

}