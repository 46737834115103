/* Always set the map height explicitly to define the size of the div
 * element that contains the map. */
#map {
    width: 100%;
    padding-top: 50%;
}

#infowindow-content .title {
    font-weight: bold;
}

#infowindow-content {
    display: none;
}

#map #infowindow-content {
    display: inline;
}

.pac-controls {
    display: inline-block;
    padding: 5px 11px;
}

#pac-input {
    padding: 0px 11px 0px 13px;
    text-overflow: ellipsis;
    width: 100%;
}
