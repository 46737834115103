@import 'datetime.scss';
@import 'map.scss';

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.button {
  margin-right: 10px;
}

.button-delete {
  @include button(false, red, auto, auto, hollow);
}

.image-preview {
  max-width: 400px;
  max-height: 300px;
  margin-bottom: 15px;
}

.loading-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.inline-loading-image {
  height: 2rem;
  width: 2rem;
}

.object-menu-text {
  color: $primary-color !important;
  cursor: pointer;

  &:hover,
  &.active {
    background-color: $light-gray !important;
  }
}

.nested {
  margin: 0rem 1rem;
}

//The content within the collaspable area
.Collapsible__contentInner {
  p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

//The link which when clicked opens the collapsable area
.Collapsible__trigger {
  display: block;
  font-weight: bold;
  position: relative;
  border: 1px solid white;
  background: white;
  padding: calc(0.7rem - 4px) 1rem;
  margin: 0px -1px;
  color: #1779ba;

  &:hover,
  &.active {
    background-color: $light-gray !important;
  }

  &:after {
    content: '▼';
    position: absolute;
    right: 10px;
    top: 7px;
    display: block;
    transition: transform 200ms;
  }

  &.is-open {
    &:after {
      transform: rotateZ(180deg);
    }
  }

  &.is-disabled {
    opacity: 0.5;
    background-color: grey;
  }
}

.sort-button {
  margin-bottom: 0px;
  margin-right: 0.25rem;
  font-size: 0.8em;
  padding: 0.75em 1em;
}

.dropzoneImage {
  padding: 50px 25px;
  border: 2px dashed;
  text-align: center;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
}

.fluxible_event_type {
  font-weight: bold;
  color: rgb(150, 146, 146);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.875rem;
  line-height: 1rem;
  margin-bottom: 0;
}
.fluxible_event_state {
  font-weight: bold;
}

.search {
  padding: 10px 20px 10px 10px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.text-centered {
  text-align: center;
}

.form-error {
  margin-top: 0.5rem;
}

.form-margin-bottom {
  margin-bottom: 1rem;
}

.permalink {
  font-style: italic;
  color: #666;
  font-size: 0.8rem;
}

.gallery {
  @include xy-grid(horizontal, true);
  @include xy-grid-layout(6, '.gallery-item');

  .gallery-item {
    border: 1px solid #333;
    background-color: #eee;
    margin-bottom: 20px;
    overflow: hidden;

    &:hover {
      cursor: pointer;
    }

    .thumbnail {
      max-height: 100px;
      overflow: hidden;
      text-align: center;
      line-height: 100px;

      img {
        max-height: 100px;
      }
    }
  }
}

.testimonial-input {
  white-space: normal;
}

.spacer-bottom {
  margin-bottom: 20px;
}

.build-status {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  display: inline-block;
  background-color: #ccc;
}
.build-status--success {
  background-color: green;
}
.build-status--building {
  background-color: blue;
}
.build-status--failed {
  background-color: red;
}
.build-status--infrastructure_failure {
  background-color: orange;
}

.current-with-input {
  padding: 0.1075rem 0.125rem;
  background: #1779ba;
  color: #fefefe;
  cursor: default;
}

.pagination-input {
  min-height: 1.4rem;
  height: auto;
  background-color: #1779ba;
  border: 0.08rem solid #1779ba;
  color: white;
  padding: 0rem 0.42rem;
  line-height: 1rem;
  margin: 0rem;
  font-size: 0.875rem;
  box-sizing: content-box;
}

.pagination-input:focus {
  background-color: #1779ba;
  border: 0.08rem solid white;
}

.pagination-input::placeholder {
  color: white;
}

.pagination-input:focus::placeholder {
  color: #1779ba;
}
