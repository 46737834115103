.small-notification-message {
  display: inline-block;
  width: 100%;
  &__item {
    border: 1px solid #ccc;
    padding: 10px 10px 10px 30px;
    background-color: #fff;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 15px;
      top: 0;
      height: 100%;
      background-color: transparent;
    }
  }
}

.notification-message {
    position: relative;
    overflow: hidden;
    min-height: 400px;
  &__item {
    border: 1px solid #ccc;
    padding: 15px 10px 15px 30px;
    margin: 20px 0;
    background-color: #fff;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 15px;
      top: 0;
      height: 100%;
      background-color: transparent;
    }
  }
  &__item--enter {
    transform: translateX(100%);
    background-color: #ddd;
  }
  &__item--enter-active {
    transform: translateX(0%);
    background-color: #fff;
    transition: all .6s;
  }
  &__item--exit {
    opacity: 1;
  }
  &__item--exit-active {
    opacity: 0;
    transition: all .6s;
  }

  &__item--success {
    &:after {
      background-color: #368a55;
    }
  }
  &__item--warning {
    &:after {
      background-color: #e7aa39;
    }
  }
  &__item--error {
    &:after {
      background-color: #c2524a;
    }
  }
}